import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/books/order/orders", { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/books/order/orders/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get("/books/order/clients")
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/orders/user/users", { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
